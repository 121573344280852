<template>
  <v-app>
    <v-main>
      
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Cookie from "js-cookie";
export default {
  name: "App",
  data: () => ({
    path: [
      {
        name: "Home",
        title: "首页",
      },
      {
        name: "ThreeModel",
        title: "部件",
      },
      {
        name: "ComfortActuators",
        title: "舒适型电机系统",
      },
      {
        name: "Motor",
        title: "动力电机系统",
      },
    ],
    num: 0,
  }),
  computed: {
    ...mapState(["back"]),
    currentPath() {
      return this.path.filter((item) => item.name === this.$route.name)[0];
    },
  },
  methods: {
    ...mapActions(["SET_BACK"]),
    goBack() {
      const barArr = [...this.back];
      const target = barArr.pop();

      if (barArr.length == 0) {
        this.$router.push({
          path: `/`,
        });
      } else {
        target.query = this.$route.query;
        this.$router.push(target);
      }
      Cookie.set("back", JSON.stringify(barArr));
      this.SET_BACK(barArr);
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    requestFullScreen() {
      var de = document.documentElement;
      if (de.requestFullscreen) {
        de.requestFullscreen();
      } else if (de.mozRequestFullScreen) {
        de.mozRequestFullScreen();
      } else if (de.webkitRequestFullScreen) {
        de.webkitRequestFullScreen();
      }
    }
  },
  created() {
    // alert(document.documentElement.clientHeight);
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = document.documentElement.clientHeight * 0.01;
    // let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    // We listen to the resize event
    window.addEventListener("resize", () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    });
  },
  mounted() {
  },
};
</script>

<style lang="sass" scoped>
#app
  margin: 0
  width: 100%
  height: 100%
  main
    height: 100%
  .headerTitle
    letter-spacing: 0px
</style>
