import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import ECharts from "vue-echarts";
import { use } from "echarts/core";
// import ECharts modules manually to reduce bundle size
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart, LineChart } from "echarts/charts";
import EasyRefresh from 'vue-easyrefresh';
import DatGui from '@cyrilf/vue-dat-gui';
import globalConfig from './config';
// import c-swipe files into main.js
import 'c-swipe/dist/swipe.css';
import { Swipe, SwipeItem } from 'c-swipe';
import { Carousel3d, Slide } from 'vue-carousel-3d';

// global register components
Vue.component('swipe', Swipe);
Vue.component('swipe-item', SwipeItem);
Vue.component('carousel-3d', Carousel3d);
Vue.component('slide', Slide);

import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  DataZoomComponent
} from "echarts/components";

use([
  CanvasRenderer,
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  DataZoomComponent,
  PieChart,
  BarChart,
  LineChart,
]);

// register globally (or you can do it locally)
Vue.component("v-chart", ECharts);
Vue.prototype.globalConfig = globalConfig;

import "./global.sass";
Vue.config.productionTip = false;
Vue.use(EasyRefresh);
Vue.use(DatGui);
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
