import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: {
      role: "",
      username: ""
    },
    category: [],
    back: [],
    currentPicIndex: 0
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setCategory(state, category) {
      state.category = category;
    },
    setBack(state, back) {
      state.back = back;
    }
  },
  actions: {
    SET_USER({ commit }, user) {
      commit("setUser", user);
    },
    SET_CATEGORY({ commit }, category) {
      commit("setCategory", category);
    },
    SET_BACK({ commit }, back) {
      commit("setBack", back);
    }
  },
  modules: {},
});
