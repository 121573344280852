import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const Home = () => import(/* webpackChunkName: "home" */ "../views/HomeView.vue");
const ThreeModel = () => import(/* webpackChunkName: "threemodel" */ "../views/ThreeModelView/ThreeModel.vue");
const TwoModel = () => import(/* webpackChunkName: "twomodel" */ "../views/ThreeModelView/TwoModel.vue");
const ComfortActuators = () => import(/* webpackChunkName: "comfortactuators" */ "../views/ComfortActuators/ComfortActuators.vue");
const PowerActuators = () => import(/* webpackChunkName: "poweractuators" */ "../views/PowerActuators/PowerActuators.vue");
const SystemsDetail = () => import(/* webpackChunkName: "systemsdetail" */ "../views/SystemsDetail.vue");
const WiperSystems = () => import(/* webpackChunkName: "wipersystems" */ "../views/WiperSystems/WiperSystems.vue");
const ThermalSystems = () => import(/* webpackChunkName: "thermalsystems" */ "../views/ThermalSystems/ThermalSystems.vue");

const pathMap = [
  {
    name: "Home",
    title: "首页",
  },
  {
    name: "ThreeModel",
    title: "3d部件",
  },
  {
    name: "TwoModel",
    title: "2d部件",
  },
  {
    name: "ComfortActuators",
    title: "舒适性电机系统",
  },
  {
    name: "PowerActuators",
    title: "动力驱动电机系统",
  },
  {
    name: "SystemsDetail",
    title: "系统零件",
  },
  {
    name: "WiperSystems",
    title: "雨刮系统",
  },
  {
    name: "ThermalSystems",
    title: "热管理系统",
  }
]



const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/ThreeModel",
    name: "ThreeModel",
    component: ThreeModel,
  },
  {
    path: "/TwoModel",
    name: "TwoModel",
    component: TwoModel,
  },
  {
    path: "/ComfortActuators",
    name: "ComfortActuators",
    component: ComfortActuators,
  },
  {
    path: "/PowerActuators",
    name: "PowerActuators",
    component: PowerActuators,
  },
  {
    path: "/SystemsDetail",
    name: "SystemsDetail",
    component: SystemsDetail,
  },
  {
    path: "/WiperSystems",
    name: "WiperSystems",
    component: WiperSystems,
  },
  {
    path: "/ThermalSystems",
    name: "ThermalSystems",
    component: ThermalSystems,
  }
];

const router = new VueRouter({
  mode: "hash",
  routes
});

router.beforeEach((to, from, next) => {
  next();
});


export default router;
